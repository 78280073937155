//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDateformat } from '../../util/getDate';
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      rows: 10,
      page: 1,
      total_number: 0,
      content: '',
      start_time: '',
      end_time: '',
      pay_sn: '',
      score: 0,
      list: [],
      timeVal: '',
      levelSelect: [
        {
          val: 0,
          label: '全部',
        },
        {
          val: 1,
          label: '一级',
        },
        {
          val: 2,
          label: '二级',
        },
        {
          val: 3,
          label: '三级',
        },
        {
          val: 4,
          label: '四级',
        },
        {
          val: 5,
          label: '五级',
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
        this.getList();
      } else {
        this.page = val;
        this.getList();
      }
    },
    cancelSearch() {
      this.content = '';
      this.pay_sn = '';
      this.score = 0;
      this.timeVal = '';
      this.start_time = '';
      this.end_time = '';
      this.getList();
    },
    getTime(val) {
      this.start_time = val[0].getTime() / 1000;
      this.end_time = val[1].getTime() / 1000;
    },
    getList() {
      let data = {
        content: this.content,
        pay_sn: this.pay_sn,
        score: this.score,
        page: this.page,
        rows: this.rows,
        start_time: this.start_time,
        end_time: this.end_time,
      };
      this.$axios.post(this.$api.samecity.service.comment, data).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          this.total_number = res.result.total_number;
          this.list = list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
